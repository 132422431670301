import React, { useState, useEffect } from 'react';
import './Header.css';

export default function Header() {
    const [navContent, setNavContent] = useState(false);
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    useEffect(() => {
        function handleResize() {
            setMobileVersion(window.innerWidth <= 885);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleNavbarClick = () => {
        setNavContent(!navContent);
    }



    return (
        <header className={mobileVersion ? 'header' : 'header header-web'}>
            {mobileVersion ? (
                <>
                    <div className="navbar" >
                        <input className="checkbox" type="checkbox" name="" id="" onClick={handleNavbarClick}/>
                        <div className="hamburger-lines">
                            <span className='line-bg line1'></span>
                            <span className='line-bg line2' ></span>
                            <span className='line-bg line3'></span>
                        </div>
                    </div>
                    {
                        navContent && (<div className='nav-container'>
                            <ul className='header-pages-nav'>
                                <li><a href='/ballina'>BALLINA</a></li>
                                <li><a href='/rreth-nesh'>RRETH NESH</a></li>
                                <li><a href='/kontakti'>KONTAKTI</a></li>
                            </ul>
                        </div>)
                    }
                </>
            ) : (
                <ul className='header-pages'>
                    <li><a href='/ballina'>BALLINA</a></li>
                    <li><a href='/rreth-nesh'>RRETH NESH</a></li>
                    <li><a href='/kontakti'>KONTAKTI</a></li>
                </ul>
            )}
        </header>
    );
}
