import MapComponent from '../../Components/Map/Map';
import React, { useState, useEffect } from 'react';
import './Contact.css'
export default function Contact() {
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    useEffect(() => {
        const handleResize = () => {
            setMobileVersion(window.innerWidth <= 885);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <main id="contact">
            <div id={mobileVersion ? 'contact-container-mb' : 'contact-container'}>
                <h1 id={mobileVersion ? 'h1-mb' : 'h1'}>prishtinë</h1>
                <div id={mobileVersion ? 'contact-form-mb' : 'contact-form'}>

                    <div id={mobileVersion ? 'contact-us-mb' : 'contact-us'}>
                        <div id='email'>
                            <h3>Email</h3>
                            <p> <a href="mailto:autoshkollatiti@hotmail.com">autoshkollatiti@hotmail.com</a></p>
                        </div>
                        <div id='phone'>
                            <h3>Numri i telefonit</h3>
                            <p><a href="tel:+38344114807">+383 44 114 807</a></p>
                        </div>
                        <div id='location'>
                            <h3>Adresa</h3>
                            <p><a target="_blank" href=" https://www.google.com/maps/dir//42.65510177612305,21.160823822021484/@42.65510177612305,21.160823822021484z?entry=ttu">Rr.Eqrem Qabej,<br /> Kompleksi Lesna përball Fakultetit Filologjik <br /> 10000 Pristina, Kosovo </a></p>
                        </div>
                    </div>

                    <MapComponent />
                </div>
            </div>
        </main>
    );
}