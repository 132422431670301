import { useEffect, useRef, useState } from "react";
import "./Home.css";
export default function Home() {
    const [color, setColor] = useState(false);
    const [secondColor, setSecondColor] = useState(false);
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    const [counter, setCounter] = useState(0);
    const sectionTwoRef = useRef(null);
    useEffect(() => {
        const incrementCounter = () => {
            if (counter < 10) {
                setCounter((prevCounter) => prevCounter + 1);
            }
        };

        const handleResize = () => {
            setMobileVersion(window.innerWidth <= 885);
        };

        const interval = setInterval(incrementCounter, 100); // Increment the counter every second

        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', handleResize);
        };
    }, [counter]);

    useEffect(() => {
        const changeColor = () => {
            if (window.scrollY >= 400) {
                setColor(false);
                setSecondColor(true);
            } else if (window.scrollY >= 1) {
                setColor(true);
                setSecondColor(false);
            } else {
                setColor(false);
                setSecondColor(false);
            }
        };

        window.addEventListener('scroll', changeColor);


        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);
    const scrollToSectionTwo = () => {

        if (sectionTwoRef.current) {
            sectionTwoRef.current.scrollIntoView({
                behavior: "smooth",
            });
        } else {
            console.log("sectionTwoRef.current is null");
        }
    };

    return (
        <main id={color ? "home-bg-one" : secondColor ? "home-bg-two" : "home"}>
            <div className="main-section">
                <div className={mobileVersion ? 'mobile-section-one' : 'section-one'}>
                    <div className={mobileVersion ? 'mobile-container' : 'container'}>
                        <div className={mobileVersion ? 'title-mobile' : 'title'}>
                            <p>AUTOSHKOLLA TITI</p>
                            <p>Aftësimi juaj, detyra jonë...</p>
                        </div>
                    </div>
                    <div className={mobileVersion ? 'mobile-logo-animation' : 'logo-animation'}>
                    </div>
                </div>
                <div className="gif">
                    {color ? (
                        <>
                            <iframe title="iframe" id="iframe_id" src="https://lottie.host/?file=306a6500-fc17-41fb-9c0a-ecc96debd4e5/7SYkGv6gj9.json"></iframe>
                            <div class="overlay" onClick={scrollToSectionTwo}></div>
                        </>
                    ) : secondColor ? (
                        <>
                            <iframe title="iframe" id="iframe_id" onClick={scrollToSectionTwo} src="https://lottie.host/?file=4bec9310-8375-4f42-9edc-aa19fb5ef0ec/SYPAbPnnqX.json"></iframe>
                            <div class="overlay" onClick={scrollToSectionTwo}></div></>
                    ) : (
                        <>
                            <iframe title="iframe" id="iframe_id" onClick={scrollToSectionTwo} src="https://lottie.host/?file=ea83a608-d5c9-46f1-9a56-f4f509204696/z5ZkBoUpJ2.json"></iframe>
                            <div class="overlay" onClick={scrollToSectionTwo}></div></>
                    )}
                </div>
            </div>
            <div className={mobileVersion ? 'section-two-mb' : 'section-two'} ref={sectionTwoRef}>
                <h1 className={mobileVersion ? 'h1-mobile' : ''}>Mirë se vini në Autoshkollën Titi</h1>

                <div className={mobileVersion ? 'experience-mv' : 'experience'}>
                    <p className="center-p">me përvojë të dëshmuar</p>

                    <h1 id="years" className={mobileVersion ? 'years' : ''}>{counter}+</h1>
                </div>
                <div className="summary">
                    <p className={mobileVersion ? 'p-mobile' : ''}>Ju garantojmë aftësimin tuaj me shërbimet që i ofrojmë.</p>
                </div>
                <div className={mobileVersion ? 'contact-button-mb' : 'contact-button'}>
                        <h3 className={mobileVersion && 'mb-link' }>
                            <a href='/kontakti' className="button-6">për çmime dhe oferta na kontaktoni</a>
                        </h3>

                    </div>

                <div>
                </div>
            </div>
        </main>
    );
}