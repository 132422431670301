import './About.css'
import React, { useState, useEffect, useRef } from 'react';
import slide1Image from '../../images/Foto Autoshkolla (12).jpg';
import slide2Image from '../../images/banner.jpg';
import slide3Image from '../../images/Foto Autoshkolla (155).jpg';
import slide4Image from '../../images/Foto Autoshkolla (36).jpg';
import slide5Image from '../../images/Foto Autoshkolla (68).jpg';
import slide6Image from '../../images/Foto Autoshkolla (80).jpg';
import slide7Image from '../../images/Foto Autoshkolla (163).jpg';
import course from '../../images/btbb.png'
import driver from '../../images/driver.png'
import exam from '../../images/fgbfgb.png'
import { LiaExternalLinkAltSolid } from "react-icons/lia";
export default function About() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sectionTwoRef = useRef(null);
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    const slides = [
        { id: 1, image: slide1Image },
        { id: 2, image: slide2Image },
        // { id: 3, image: slide3Image },
        { id: 4, image: slide4Image},
        // { id: 5, image: slide5Image},
        // { id: 6, image: slide6Image},
        { id: 7, image: slide7Image},
    ];
    useEffect(() => {


        const handleResize = () => {
            setMobileVersion(window.innerWidth <= 885);
        };

        window.addEventListener('resize', handleResize);

        return () => {

            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000);


        return () => clearInterval(interval);
    }, [currentSlide, slides.length]);
    const scrollToSectionTwo = () => {
        if (sectionTwoRef.current) {
            sectionTwoRef.current.scrollIntoView({
                behavior: "smooth",
            });
        } else {
            console.log("sectionTwoRef.current is null");
        }
    };

    const onScroll = () => {
        if (sectionTwoRef.current) {
            const sectionTwoTop = sectionTwoRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (sectionTwoTop < windowHeight / 2) {
                sectionTwoRef.current.classList.add('visible');

                setTimeout(() => {

                    const servicesText = sectionTwoRef.current.querySelector('.services-text');
                    if (servicesText) {
                        servicesText.classList.add('services-animation');
                    }
                    const stepOneAnim = sectionTwoRef.current.querySelector('.step-one');
                    if (stepOneAnim) {
                        stepOneAnim.classList.add('step-one-animation');
                    }
                    const stepTwoAnim = sectionTwoRef.current.querySelector('.step-two');
                    if (stepTwoAnim) {
                        stepTwoAnim.classList.add('step-two-animation');
                    }
                    const stepThreeAnim = sectionTwoRef.current.querySelector('.step-three');
                    if (stepThreeAnim) {
                        stepThreeAnim.classList.add('step-three-animation');
                    }
                    const subTitleElements = sectionTwoRef.current.querySelectorAll('.sub-title');
                    subTitleElements.forEach((subTitleElement) => {
                        subTitleElement.classList.add('animation');
                    });
                    const missionText = sectionTwoRef.current.querySelector('.mission-text');
                    if (missionText) {
                        missionText.classList.add('mission-animation');
                    }

                }, 100);
                window.removeEventListener('scroll', onScroll);
            }
        }
    };
    window.addEventListener('scroll', onScroll);



    return (
        <main id="about-us">
            <div className='main-container'>
                <div className='about'>
                    <div className='text'>
                        <h1>RRETH NESH</h1>
                        <p>
                            Autoshkolla TITI, e themeluar në vitin 2009, ka një staf tërheqës me një përvojë të gjatë dhe profesionalizëm të pasur. Ne specializohemi në aftësimin e kandidatëve në të dy pjesët, teorike dhe praktike.

                            Për ne, përkushtimi ndaj aftësimit të kandidatëve është në qendër të veprimtarisë sonë. Ne ofrojmë mësime cilësore dhe sigurojmë instruktora të kualifikuar dhe të aftë profesionalisht për të siguruar një komunikacion të shkëlqyeshëm me studentët tanë.
                        </p>
                        <div className='button'>
                            <button onClick={scrollToSectionTwo}>ÇKA OFROJMË?</button>
                        </div>
                    </div>

                </div>


                {slides.map((slide, index) => (
                    <div
                        key={slide.id}
                        id='img-animation'
                        className={`slide ${index === currentSlide ? 'active' : ''}`}
                    >

                        <img style={{ objectFit: 'cover !important' }} className='img' src={slide.image} alt={`Slide ${index + 1}`} />

                    </div>
                ))}


            </div>
            <div className='second-container' ref={sectionTwoRef}>
                <div className='services'>
                    <div className='journey'>
                        <h1 className='sub-title'>Filloni rrugëtimin tuaj për patent shofer</h1>
                        <div className={mobileVersion ? 'journey-steps-mb' : 'journey-steps'}>
                            <div className={mobileVersion ? 'step-one step step-mb' : 'step-one step'}>
                                <img src={course} alt='illustations' />
                                <p>Trajnimi teorik për shofer</p>
                            </div>
                            <div className={mobileVersion ? 'step-two step step-mb' : 'step-two step'}>
                                <img src={driver} alt='illustations' />
                                <p>Trajnimi praktik për shofer</p>
                            </div>
                            <div className={mobileVersion ? 'step-three step step-mb' : 'step-three step'}>
                                <img src={exam} alt='illustations' />
                                <p>Përgaditje për provimin praktik</p>
                            </div>

                        </div>

                    </div>
                    <hr />
                    <div id={mobileVersion ? 'services-mb' : 'services'} className='services-text'>

                        Autoshkolla jonë ofron shërbime kualitative për aftësim në kategoritë

                        <div id={mobileVersion ? 'flip-mb' : 'flip'}>
                            <div><div>"B" për vetura automatike dhe manuale</div></div>
                            <div><div>"C" për kamionë</div></div>
                            <div><div>"D" për autobusë</div></div>
                        </div>
                    </div>

                    <hr />
                    <div id='mission'>
                        <h1 className='sub-title'>Misioni ynë</h1>
                        <p className='mission-text'>
                            Misioni i Autoshkolles TITI përqendrohet në ofrimin e një aftësimi të jashtëzakonshëm dhe cilësor për kandidatët tanë në drejtimin e automjeteve si veturave automatike dhe manuale, kamionëve dhe autobusëve. Ne vlerësojmë përkushtimin ndaj cilësisë së mësimit dhe synojmë të zhvillojmë një mjedis mësimor të përgjegjshëm dhe inovativ. Përvoja e mësimeve praktike sigurohet përmes instruktorëve tanë të kualifikuar, të cilët përdorin automjete të reja dhe metodologji të përparuara mësimore.
                            <br /><br />
                            Misioni ynë është të krijojmë një udhëtim të paharrueshëm drejt njohjes së rregullave dhe sigurisë në rrugë, duke dhënë rëndësi çdo detaji që ndihmon në formimin e shoferëve të sigurtë, të përgjegjshëm dhe të shkathtë. Nëpërmjet përkushtimit tonë ndaj cilësisë dhe ndërveprimit pozitiv, synojmë të ndihmojmë studentët tanë të arrijnë një nivel të lartë aftësish dhe të bëhen pjesë e shoqërisë së sigurt në rrugë.
                        </p>
                    </div>
                    <div className='contact-link'>
                        <h3 className={mobileVersion && 'mb-link'}><a href='/kontakti'>për çmime dhe oferta na kontaktoni <LiaExternalLinkAltSolid /> </a></h3>

                    </div>

                </div>
            </div>
        </main>
    );
}