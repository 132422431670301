import PuffLoader from "react-spinners/PuffLoader";
import './App.css';
import { useEffect, useState } from "react";
import Home from './Pages/Home Page/Home';
import { Route, Routes, BrowserRouter as Router, Navigate } from "react-router-dom";
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import About from './Pages/About us Page/About';
import Contact from './Pages/Contact Page/Contact';
import NotFound from "./Components/NotFound/NotFound";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1800)
  }, [])
  return (
    <div className="App">
      {loading ?
        <div className='loader'>
          <PuffLoader

            color="#D91A32"
            loading={loading}

            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        : (
          <>
            <Header />
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/ballina" />} />
                <Route path="/ballina" element={<Home />} />
                <Route path="/rreth-nesh" element={<About />} />
                <Route path="/kontakti" element={<Contact />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Router>


            <Footer />
          </>
        )
      }

    </div>
  );
}

export default App;
