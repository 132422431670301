import './NotFound.css'
import React, { useEffect, useState } from 'react';
export default function NotFound() {
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    useEffect(() => {


        const handleResize = () => {
            setMobileVersion(window.innerWidth <= 885);
        };

        window.addEventListener('resize', handleResize);

        return () => {
           
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='pageNotFound'>
            <div className="notfound">
                
                <h1 className='notfound-h1'>404</h1>
                <h2 className='notfound-h2'>UH OH! You're lost.</h2>
                <p>The page you are looking for does not exist.
                    How you got here is a mystery. But you can click the button below
                    to go back to the homepage.
                </p>
                <button className={mobileVersion ? 'btn red' : 'btn btn-1 red'}><a href='/'>BALLINA</a></button>
            </div>
        </div>

    );
}
