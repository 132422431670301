import "./Footer.css"
import { SlLocationPin } from "react-icons/sl";
import {  CiMail, CiFacebook, CiInstagram } from "react-icons/ci";
import { PiPhoneIncomingThin } from "react-icons/pi";
import { useEffect, useState } from "react";

export default function Footer() {
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);


    useEffect(() => {
        function handleResize() {
            setMobileVersion(window.innerWidth <= 885);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    return (

        <footer>
            <div className={mobileVersion ? 'company-information-mb' : 'company-information'}>
                <div className="company">
                    <h3 className="company">Autoshkolla Titi</h3>
                    <div className="pages">
                        <ul className="footer-pages">
                            <li><a href="/ballina">BALLINA</a></li>
                            <li><a href="/rreth-nesh">RRETH NESH</a></li>
                            <li><a href="/kontakti">KONTAKTI</a></li>
                        </ul>
                    </div>
                </div>
                <div className="address-information">
                    <h3><SlLocationPin /> Adresa</h3>
                    <p><a target="_blank" rel="noopener noreferrer" href=" https://www.google.com/maps/dir//42.65510177612305,21.160823822021484/@42.65510177612305,21.160823822021484z?entry=ttu">Rr.Eqrem Qabej,<br /> Kompleksi Lesna përball Fakultetit Filologjik <br /> 10000 Pristina, Kosovo </a></p>
                </div>
                <div className="contact-information">
                    <h3><CiMail /> Email</h3>
                    <p> <a rel="noopener noreferrer" href="mailto:autoshkollatiti@hotmail.com">autoshkollatiti@hotmail.com</a></p>
                    <br />
                    <h3><PiPhoneIncomingThin /> Numri telefonit</h3>
                    <p><a rel="noopener noreferrer" href="tel:+38344114807">+383 44 114 807</a></p>
                </div>
            </div>
            <div className={mobileVersion ? 'socials-mb' : 'socials'}>
                <p className={mobileVersion && 'p-footer-mobile'}>Na gjeni në rrjetet sociale </p>
                <div className="social-medias">
                    <a rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100063571901002" target="_blank"><CiFacebook /></a>
                    <a rel="noopener noreferrer" href="https://www.instagram.com/autoshkolla_titi/" target="_blank"> <CiInstagram /></a>
                </div>
            </div>
            <div className={mobileVersion ? 'development-information-mb content-text' : 'development-information content-text'}>
                <div className="rights">
                    <p>2023 © All rights reserved</p>
                </div>
                <div className="author">
                    <p>Developed by Dalma Smaka | <a href="mailto:smakadalma@gmail.com">smakadalma@gmail.com</a></p>
                </div>
            </div>
        </footer>

    );

}