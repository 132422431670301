
import './Map.css'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';

const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '400px',
};
const center = {

    lat: 42.65510177612305,
    lng: 21.160823822021484,
};

const App = () => {
    const [mobileVersion, setMobileVersion] = useState(window.innerWidth <= 885);
    useEffect(() => {


        const handleResize = () => {
            setMobileVersion(window.innerWidth <= 885);
        };

        window.addEventListener('resize', handleResize);

        return () => {
           
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAWbd4J27GvDyKdWT_i41w414pU8EIgxZo',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <div className={mobileVersion ? 'map-mb' : 'map'}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={14}
                center={center}
            >
                <MarkerF position={center} />
            </GoogleMap>
        </div>
    );
};

export default App;